import React from 'react'
import './Hero.css'
import Header from '../Header/Header'
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import Heart from "../../assets/heart.png"
export const Hero = () => {
  return (
    <>
    <div className="hero">
            <div className="left-h">
  <Header/>
  <div className="the-best-ad">
    <div></div>
    <span>
      the best fitness club in the town
    </span>
  </div>
  <div className="hero-text">
      <div>
        <span className="stroke-text">
          shape
        </span>
        <span>your</span>
      </div>
      <div>ideal body</div>
          </div>
          <div>
            <span className='hero-text-4'>
              In here we will help you to shape and build your ideal live up your to the fullest
            </span>
          </div>
          <div className="figures">
            <div><span>+140</span><span>expert coaches</span></div>
          <div><span>+978</span><span>members joined</span></div>
          <div><span>+50</span><span>fitness program</span></div>
          </div>
          <div className="hero-buttons">
            <buttons className="btn">GET STARTED</buttons>
            <buttons className="btn">LEARN MORE</buttons>
          </div>
          </div>
            <div className="right-h">
              <buttons className="btn">JOIN NOW</buttons>
              
              <div className="heart-rate">
                <img src={Heart} alt=""/>
                <span>heart rate</span>
                <span>+116bpm</span>
              </div>
              
              <img src={hero_image} alt=''className='hero-image'/>
              <img src={hero_image_back} alt='' className='hero-image-back'/>
              
  </div>
    </div>
  
    </>
  )
}
export default Hero