import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
const Join=()=>{
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_68tmizd', 'template_7cb9spc', form.current, {
            publicKey: 'PEarDetAoOc9x4bf2',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };
  return (
    <div className="join" id="join-us">
        <div className='left-j'>
            <hr />
            <div>
            <span className='stroke-text'>GIVE YOUR  </span>
            <span>FEEDBACK TO MAKE</span>
            </div>
            <div>
            <span>THIS WEBSITE </span>
            <span className='stroke-text'>BETTER HERE</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className="email-container" onSubmit={sendEmail}>
                <input type='email' name='user-email' placeholder='enter your email address'></input>
        <button className='btn btn-j'>Give Feedback here</button>
            </form>
        </div>
        </div>
        
    
  )
}

export default Join